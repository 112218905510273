<template>
  <div class="czh container" v-if="canShow">
    <Tanchuang :path="path" :id="id" />
    <Kcz :path="path" :id="id" />
    <div class="header">
      <img :src="czh.logo" class="header-logo" />
      <div class="header-right">
        <div class="line1">
          <div class="czh-name">{{ czh.name }}</div>
          <div class="guanzhu linkApp">
            <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
              <script type="text/wxtag-template">
                <style>
                  .btn{
                    width: 100%;
                    height: 25px;
                  }
                </style>
                  <div class="btn"></div>
              </script>
            </wx-open-launch-app>
            <img src="@/assets/images/guanzhu.png" @click="linkApp" />
          </div>
        </div>
        <div class="line2">
          <!-- 发布<span class="fabu">{{ czh.fabu }}</span> -->
          粉丝
          <span class="fensi">{{ czh.fensi }}</span>
          影响力
          <span class="yingxiang">{{ czh.yingxiang }}</span>
        </div>
        <!-- <div class="line3">
          认证：<span>{{ czh.renzheng }}</span>
        </div> -->
        <div class="line4 summary">{{ czh.summary }}</div>
      </div>
    </div>
    <div class="tabs" v-show="tabs.length > 0">
      <div class="tab linkApp" :class="index === 0 ? 'active' : ''" @click="linkApp" v-for="(item, index) in tabs" :key="index">
        <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
          <script type="text/wxtag-template">
            <style>
              .btn{
                width: 100%;
                height: 40px;
              }
            </style>
              <div class="btn"></div>
          </script>
        </wx-open-launch-app>
        {{ item.name }}
      </div>
    </div>
    <Tuijian :list="list" :isCzh="true" @link="openlink" :path="path" :id="id" />
    <OpenApp2 :path="path" :id="id" />
  </div>
</template>

<script>
  import Tanchuang from "@/components/tanchuang";
  import Kcz from "@/components/kcz";
  import Tuijian from "@/components/tuijian";
  import OpenApp2 from "@/components/openApp2";
  import linkApp from "@/utils/linkApp";
  import { getCzh, getCzhTimeline } from "@/utils/api";
  import { isWeixin } from "@/utils/common";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  export default {
    name: "czh",
    data() {
      return {
        canShow: false,
        id: "",
        path: "czInfo",
        list: [],
        tabs: [],
        czh: {
          logo: "",
          name: "",
          fabu: "",
          fensi: "",
          yingxiang: "",
          renzheng: "",
          summary: ``,
        },
        share_config: {},
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
      };
    },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          id: this.id,
        });
      },
    },
    components: { Kcz, Tanchuang, Tuijian, OpenApp2 },
    methods: {
      openError() {
        this.$router.push({ name: "index" });
      },
      linkApp() {
        const data = {
          path: "czInfo",
          param: this.id,
        };
        linkApp(data);
      },
      async getCzh(id) {
        const res = await getCzh(id);
        const data = res.data;
        this.czh.logo = data.avatar;
        this.czh.name = data.title;
        this.czh.fensi = data.follower_count;
        this.czh.yingxiang = data.hot;
        this.czh.summary = data.summary;
        let tabs = data.categories.data.slice(0, 3).map(val => {
          return {
            name: val.title,
            id: val.id,
          };
        });
        this.tabs = tabs;
        this.getCzhTimeline(this.id, this.tabs[0].id);
        this.share_config = data.share_config;
        this.canShow = true;
        document.title = data.title;
        wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
      },
      async getCzhTimeline(id, categoryId) {
        const res = await getCzhTimeline(id, categoryId);
        const data = res.data;
        if (res.data.length > 0) {
          this.list = res.data.slice(0, 3).map(val => {
            return {
              content: val.title,
              source: val.source,
              src: val.thumbnails[0].src,
              source_link: val.source_link,
            };
          });
        }
      },
      openlink(val) {
        window.location.href = val.source_link;
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
        this.getCzh(this.id);
      } else {
        this.$router.push({ name: "index" });
      }
    },
  };
</script>

<style lang="scss">
  .czh {
    .header {
      width: 92%;
      margin: 20px auto;
      display: flex;
      .header-logo {
        flex: none;
        width: 60px;
        height: 60px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #ccc;
      }
      .header-right {
        flex: 1;
        font-size: 16px;
        color: #787878;
        .line1 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .czh-name {
            font-size: 18px;
            font-weight: 800;
            line-height: 1.5;
            color: #363636;
          }
          img {
            width: 60px;
            height: auto;
          }
        }
        .line2 {
          margin-top: 5px;
        }
        .line3 {
          margin-top: 10px;
        }
        .line4 {
          margin-top: 10px;
        }
      }
    }
    .tabs {
      width: 92%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .tab {
        // width: 33.33%;
        flex: 1;
        text-align: center;
        font-size: 20px;
        line-height: 40px;
        height: 40px;
        color: #9e9e9e;
        position: relative;
      }
      .active {
        color: #393b38;
        font-weight: 800;
        position: relative;
        &::after {
          content: " ";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 20%;
          height: 5px;
          background: #d11816;
          border-radius: 0.1rem;
        }
      }
    }
    .guanzhu {
      position: relative;
      height: 25px;
    }
  }
</style>
